<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Уточнение бюджета</div>
            <b-tabs>
                <b-tab title="Расходы" active>
                    <div class="actions-tab in-tab">
                        <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
                            <b-dropdown-text>
                                <div class="info-button">
                                    <span @click="infoCostForm(3)">Режим «Уточнение бюджета» <i class="icon icon-folder"></i></span>
                                </div>
                                <!-- <div class="info-button">
                                    <span>Видеоинструкция <i class="icon icon-video"></i></span>
                                </div> -->
                            <div class="info-text">
                                <p>Режим предназначен для внесения изменений и дополнений в
                                    утвержденный/уточненный бюджет.</p>
                                <p> Режим содержит данные до уровня бюджетных программ/подпрограмм по расходной
                                    части и специфик по поступлениям.</p>
                                <p>Режим состоит из следующих разделов:</p>
                                <p><span class="red bold">Расходы</span><br> – уточнение расходной части бюджета</p>
                                <p><span class="red bold">Доходы</span><br> – уточнение доходной части бюджета</p>
                                <p><span class="red bold">Баланс</span><br> – отображение данных уточненного бюджета по структуре бюджета</p>
                            </div>
                            </b-dropdown-text>
                        </b-dropdown>
                        <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
                            <b-dropdown-item @click="downloadExcel">Отчет по уточнению</b-dropdown-item>
                            <b-dropdown-item @click="downloadSumExcel">Сводная рабочая таблица</b-dropdown-item>
                            <b-dropdown-item @click="downloadMaslihat">Приложение к решению Маслихата</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <template v-if="region !== null">
                        <cost-clarify :year="year"
                                      :obl="obl"
                                      :reg="region"
                                      :variant="variant"
                                      :list-reg="listReg"
                                      @changeParams="changeParams"
                        ></cost-clarify>
                    </template>
                </b-tab>
                <b-tab title="Доходы">
                    <div class="actions-tab in-tab">
                        <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
                            <b-dropdown-text>
                                <div class="info-button">
                                    <span @click="infoCostForm(4)">Режим «Уточнение бюджета» <i class="icon icon-folder"></i></span>
                                </div>
                                <!-- <div class="info-button">
                                    <span>Видеоинструкция <i class="icon icon-video"></i></span>
                                </div> -->
                                <div class="info-text">
                                    <p>Режим предназначен для внесения изменений и дополнений в утвержденный/уточненный бюджет.</p>
                                    <p>Режим содержит данные до уровня бюджетных программ/подпрограмм по расходной части и специфик по доходной части бюджета.</p>
                                    <p>Режим состоит из следующих разделов:</p>
                                    <p><span class="red bold">Расход</span><br> – уточнение расходной части бюджета</p>
                                    <p><span class="red bold">Доход</span><br> – уточнение доходной части бюджета</p>
                                    <p><span class="red bold">Баланс</span><br> – отображение данных уточненного бюджета по структуре бюджета</p>
                                </div>
                            </b-dropdown-text>
                        </b-dropdown>
                        <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                                <span class="left">
                                    <i class="icon icon-download"></i> Скачать
                                </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
                            <b-dropdown-item @click="downloadExcelIncome">Отчет по уточнению</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <template v-if="region !== null">
                        <income-clarify :year="year"
                                        :obl="obl"
                                        :reg="region"
                                        :variant="variant"
                                        :list-reg="listReg"
                        ></income-clarify>
                    </template>
                </b-tab>
                <b-tab title="Баланс" class="no-filters">
                    <template v-if="region !== null">
                        <balance-tab :year="year"
                                     :obl="obl"
                                     :region="region"
                                     :variant="variant"
                        ></balance-tab>
                    </template>
                </b-tab>
            </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import CostClarify from '@/modules/budget/CostClarify';
import IncomeClarify from '@/modules/budget/IncomeClarify';
import BalanceTab from '@/modules/budget/components/BalanceTab';
import { Ax } from '@/utils';
import store from "../../services/store";
import {getActualVariant} from '@/modules/budget/budgetVariantService';

'./budgetVariantService'

export default {
    name: 'BudgetClarify',
    components: { CostClarify, IncomeClarify, BalanceTab },
    data() {
        return {
            year: 2021,

            obl: null,
            region: null,
            variant: null,

            listReg: [],

            selectedABP: { id: 0, child: [] },
            selectedPRG: { id: 0, child: [] },
            selectedPPR: { id: 0 }
        };
    },

    async mounted() {
        this.year = new Date().getFullYear();
        await this.getObl();
        await this.loadRegions();
    },
    methods: {
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.region = json.region;
                    });
                await getActualVariant(this.region).then(data => {
                    this.variant = data
                })
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() { // справочник регионов
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl +'/'+ this.$store.getters.user_uuid);
                this.listReg = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        },

        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id == 3) {
                        file = 'РП Уточнение расходной части бюджета';
                    }
                    if (info_id == 4) {
                        file = 'РП Уточнение доходной части бюджета';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        changeParams(data) {
            this.selectedABP = data.selABP;
            this.selectedPRG = data.selPRG;
            this.selectedPPR = data.selPPR;
        },
        // формирование отчета
        downloadExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/cost-clarity-download/2/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {

                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Источники поступлений.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadSumExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/summary-clarity-download/2/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводные данные.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadMaslihat() {
            const that = this;
            Ax(
                {
                    url: '/api-py/maslihat-balance-download/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Отчет_маслихат.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        /* downloadExcelIncome() {
            const that = this;
            Ax(
                {
                    url: '/api-py/cost-clarity-download/1/' + that.region + '/' + that.variant + '/' + that.year, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Уточнение доходов.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        } */
            // формирование отчета доходов
        downloadExcelIncome() {
            const that = this;
            Ax(
                {
                    url: '/api-py/cost-clarity-download/1/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Уточнение доходов.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение
    }
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>