<template>
    <div class="table-container table-balance">
        <b-table
            :fields="tableFields"
            :items="balanceForm"
            :tbody-tr-class="rowClass"
            responsive="true"
            bordered
            head-variant="light"
            sticky-header
            no-border-collapse
        >
            <template #head(action)="scope">
                <b-button @click="openAll()">
                    <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                    <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                </b-button>
            </template>
            <template #cell(action)="data">
                <b-button v-if="(data.item.parent > 0) && (data.item.parent != 5)" v-model="data.item.open"
                          @click="openChilds(data.item)">
                    <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
                    <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
                </b-button>
            </template>
            <template #cell(amount)="data">
                <div class="text-right">
                    {{ (isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value) }}
                </div>
            </template>
            <template #cell(change)="data">
                <div class="text-right">
                    {{ (isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value) }}
                </div>
            </template>
            <template #cell(correct)="data">
                <div class="text-right">
                    {{ (isNaN(data.value) || (data.value == 0)) ? 0 : $n(data.value) }}
                </div>
            </template>
            <template #cell(more)>
                <i class="icon icon-more icon-rotate-90"></i>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: 'BalanceTab',
    props: {
        year: Number,

        obl: String,
        region: String,
        variant: String
    },
    data() {
        return {
            day: 0,
            month: 0,
            curDate: 0,
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'num',
                    label: '№',
                    sortable: true
                },
                {
                    key: 'value.name_ru',
                    label: 'Наименование',
                    sortable: true
                },
                {
                    key: 'amount',
                    label: 'Утвержденный бюджет на  год',
                    variant: 'danger',
                    sortable: true
                },
                {
                    key: 'change',
                    label: 'Всего изменений',
                    variant: 'danger',
                    sortable: true
                },
                {
                    key: 'correct',
                    label: 'Уточненный бюджет на ',
                    variant: 'danger',
                    sortable: true
                },
                {
                    key: 'more',
                    label: ' '
                }
            ],
            balanceForm: [],
            dohody: {
                id: 1,
                type: 0,
                code: 1,
                name_ru: 'ДОХОДЫ',
                visible: true
            },
            rashody: {
                id: 2,
                type: 0,
                code: 2,
                name_ru: 'РАСХОДЫ',
                visible: true
            },
            budgcredit: {
                id: 3,
                type: 0,
                code: 3,
                name_ru: 'ЧИСТОЕ БЮДЖЕТНОЕ КРЕДИТОВАНИЕ',
                visible: true
            },
            saldo: {
                id: 4,
                type: 0,
                code: 4,
                name_ru: 'САЛЬДО ПО ОПЕРАЦИЯМ С ФИНАНСОВЫМИ АКТИВАМИ',
                visible: true
            },
            proficit: {
                id: 5,
                type: 0,
                code: 5,
                name_ru: 'ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА',
                visible: true
            },
            deficit: {
                id: 6,
                type: 0,
                code: 6,
                name_ru: 'ФИНАНСИРОВАНИЕ ДЕФИЦИТА (ИСПОЛЬЗОВАНИЕ ПРОФИЦИТА) БЮДЖЕТА',
                visible: true
            },
            open: false,
            curLst: [],
            calcFlds: [
                'amount',
                'change',
                'correct'
            ]
        };
    },
    async mounted() {
        this.listSrc = [
            { value: this.dohody, text: this.dohody.name_ru },
            { value: this.rashody, text: this.rashody.name_ru },
            { value: this.budgcredit, text: this.budgcredit.name_ru },
            { value: this.saldo, text: this.saldo.name_ru },
            { value: this.proficit, text: this.proficit.name_ru },
            { value: this.deficit, text: this.deficit.name_ru }];
        this.getBalance();
    },
    beforeUpdate() {
        this.day = this.padLeadingZeros(new Date().getDate(), 2);
        this.month = this.padLeadingZeros(new Date().getMonth() + 1, 2);
        this.curDate = this.day + '.' + this.month + '.' + this.year;
        this.tableFields[3].label = 'Утвержденный бюджет на ' + (this.year) + ' год';
        this.tableFields[5].label = 'Уточненный бюджет на ' + (this.curDate);

        this.balanceForm.forEach(row => {
            if (row.hasChild) {
                row._colVariant = 'info';
            }
        });
    },
    methods: {
        async getBalance() {
            try {
                const response = await fetch('/api-py/balance-dtable/'
                    + this.region + '/' + this.variant + '/' + this.year);
                this.balance = await response.json();
                const that = this;
                for (const item of that.listSrc) {
                    that.$set(item, 'num', item.value.id);
                    that.$set(item, 'parent_id', 0);
                    that.$set(item, 'parent', item.value.id);
                    that.$set(item, '_cellVariants', { action: 'info', num: 'info', 'value.name_ru': 'info', more: 'info', amount: 'warning', change: 'warning', correct: 'warning' });

                    that.$set(item, '_showDetails', true);
                    that.$set(item, 'visible', true);
                    if (item.value.id != 5) {
                        that.calcFlds.forEach(field => {
                            Object.defineProperty(item, field, {
                                get: function () {
                                    return that.reSum(item, field);
                                }
                            });
                        });
                    } else {
                        that.calcFlds.forEach(field => {
                            Object.defineProperty(item, field, {
                                get: function () {
                                    let res = 0;
                                    for (const row of that.balanceForm) {
                                        if ([1].includes(row.parent)) {
                                            res += that.reSum(row, field);
                                        }
                                        if ([2, 3, 4].includes(row.parent)) {
                                            res -= that.reSum(row, field);
                                        }
                                    }
                                    return res;
                                }
                            });
                        });
                    }
                    that.$set(that.balanceForm, that.balanceForm.length, item);
                    for (const row of that.balance) {
                        if (item.num === row.type_id) {
                            that.$set(row, 'num', item.value.id + '-' + row.code);
                            that.$set(row, 'parent_id', item.value.id);
                            that.$set(row, 'visible', false);
                            that.$set(row, 'value.name_ru', row.name_ru);
                            that.$set(that.balanceForm, that.balanceForm.length, row);
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getBalance()', error.toString());
            }
        },
        openAll() {
            this.open = !this.open;
            for (const row of this.balanceForm) {
                if (row.parent_id > 0) {
                    row.visible = this.open;
                    if ([1, 2, 3, 4, 6].includes(row.parent_id)) {
                        row.open = this.open;
                    }
                }
                else {
                    row.open = this.open;
                }
            }
        },
        openChilds(parent) {
            parent.open = !parent.open;

            for (const row of this.balanceForm) {
                if (parent.parent === row.parent_id) {
                    row.visible = parent.open;
                    if (row.hasChild && row.open !== parent.open) {
                        this.openChilds(row);
                    }
                }
            }
        }, // открывает/закрывает ветку до конечного элемента


        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        reSum(parent, field) {
            let sum = 0;
            let BK_1 = 0;
            let BK_2 = 0;
            let BK_3 = 0;
            this.balanceForm.forEach(row => {
                if ((parent.parent == row.parent_id) && ([1, 2].includes(row.parent_id))) {
                    sum += parseFloat(row[field]);
                }
                if ((parent.parent == row.parent_id) && ([3, 4].includes(row.parent_id))) {
                    switch (row.code) {
                        case '1':
                            BK_1 = parseFloat(row[field]);
                        case '2':
                            BK_2 = parseFloat(row[field]);
                    }
                    sum = BK_1 - BK_2;
                }
                if ((parent.parent == row.parent_id) && ([6].includes(parent.parent))) {
                    switch (row.code) {
                        case '1':
                            BK_1 = parseFloat(row[field]);
                        case '2':
                            BK_2 = parseFloat(row[field]);
                        case '3':
                            BK_3 = parseFloat(row[field]);
                    }
                    sum = BK_1 + BK_3 - BK_2;
                }
            });
            return parseFloat(sum.toFixed(2));
        },
        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    },
    computed: {}
};
</script>